:root {
  --pixel-size: 2px;
  --grid-cell: calc(var(--pixel-size) * 16);
  --bg: #9fa7e4;
}

@media (min-width: 700px) {
  :root {
    --pixel-size: 3px;
  }
}
@media (min-width: 1000px) {
  :root {
    --pixel-size: 4px;
  }
}

/* @media( min-width: 1000px ) {
    :root {
       --pixel-size: 5px;
    }
 } */

/* html,
body {
  height: 100%;
}

body {
  background: var(--bg);
  display: flex;
  align-items: center;
  justify-content: center;
}
 .pixel-art {
  image-rendering: pixelated;
} */

.frame {
  /* This is just for Pen decoration */
  width: calc(var(--pixel-size) * 400); /*  400 za full width */
  height: calc(var(--pixel-size) * 200);
  outline: var(--pixel-size) solid #fff;
  z-index: 1;
  position: relative;
}

.camera {
  width: calc(var(--pixel-size) * 400); /*  400 za full width */
  height: calc(var(--pixel-size) * 200);
  overflow: hidden;
  background: #61ddf7;
  position: relative;
}

.map {
  image-rendering: pixelated;
  background-image: url('../../assets/img/Map2.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: calc(22 * var(--grid-cell));
  height: calc(12 * var(--grid-cell));
  position: relative;
}

.character {
  width: calc(var(--grid-cell) * 3);
  height: calc(var(--grid-cell) * 3);
  position: absolute;
  overflow: hidden;
}

.guest {
  transition: transform 0.75s ease-in-out;
}

.meetingBubble {
  position: absolute;
  border-radius: 50%;
  width: 100px; /* calc(var(--grid-cell) * 8); */
  height: 100px; /* calc(var(--grid-cell) * 8); */
  display: block;
  background-color: rgba(149, 237, 233, 0.5);
  left: 25px;
  top: 25px;
}

/* .shadow {
  width: calc(var(--grid-cell) * 2);
  height: calc(var(--grid-cell) * 2);
  position: absolute;
  left: 0;
  top: 0;
  background: url('https://assets.codepen.io/21542/DemoRpgCharacterShadow.png') no-repeat no-repeat;
  background-size: 100%;
} */

.character_spritesheet {
  position: absolute;
  border-radius: 50%;
  width: 50px; /* calc(var(--grid-cell) * 8); */
  height: 50px; /* calc(var(--grid-cell) * 8); */
  display: block;
  top: 50px;
  left: 50px;
}

.character[facing='right'] .character_spritesheet::after {
  /* background-position-y: calc(var(--pixel-size) * -32); */
  position: absolute;
  background-color: inherit;
  border-radius: 50%;
  width: 25px; /* calc(var(--grid-cell) * 8); */
  height: 25px; /* calc(var(--grid-cell) * 8); */
  display: block;
  content: '';
  left: 60px;
  top: 12.5px;
}
.character[facing='up'] .character_spritesheet::after {
  /*  background-position-y: calc(var(--pixel-size) * -64); */
  position: absolute;
  background-color: inherit;
  border-radius: 50%;
  width: 25px; /* calc(var(--grid-cell) * 8); */
  height: 25px; /* calc(var(--grid-cell) * 8); */
  display: block;
  content: '';
  left: 12.5px;
  top: -35px;
}
.character[facing='left'] .character_spritesheet::after {
  /* background-position-y: calc(var(--pixel-size) * -96); */
  position: absolute;
  background-color: inherit;
  border-radius: 50%;
  width: 25px; /* calc(var(--grid-cell) * 8); */
  height: 25px; /* calc(var(--grid-cell) * 8); */
  display: block;
  content: '';
  left: -35px;
  top: 12.5px;
}
.character[facing='down'] .character_spritesheet::after {
  /* background-position-y: calc(var(--pixel-size) * -96); */
  position: absolute;
  background-color: inherit;
  border-radius: 50%;
  width: 25px; /* calc(var(--grid-cell) * 8); */
  height: 25px; /* calc(var(--grid-cell) * 8); */
  display: block;
  content: '';
  left: 12.5px;
  top: 60px;
}
.character[walking='false'] .character_spritesheet::after {
  display: none;
}

#mydiv {
  position: absolute;
  z-index: 9;
  background-color: #f1f1f1;
  border: 1px solid #d3d3d3;
  text-align: center;
  left: -230px;
  top: 0;
  height: 185px;
}

#mydivheader {
  padding: 10px;
  cursor: move;
  z-index: 10;
  background-color: #2196f3;
  color: #fff;
}

#videoElement {
  width: 200px;
  height: 150px;
}

#hostCall {
  display: none;
}

#startCall {
  display: none;
}

.sidebar {
  display: none !important;
}

#sidebartogglett {
  display: none !important;
}

/* @keyframes walkAnimation {
  from {
    transform: translate3d(0%, 0%, 0);
  }
  to {
    transform: translate3d(-100%, 0%, 0);
  }
} */

.dpad {
  position: absolute;
  right: calc(var(--pixel-size) * 2);
  bottom: calc(var(--pixel-size) * 2);
  width: calc(var(--pixel-size) * 37);
  height: calc(var(--pixel-size) * 38);
}
.dpad-button {
  appearance: none;
  outline: 0;
  border: 0;
  background: transparent;
  padding: 0;
  cursor: pointer;
}
.dpad-button svg {
  display: block;
  height: calc(var(--pixel-size) * 13);
}

.dpad-button.pressed .Arrow_arrow-inset {
  stroke: #07c2cc;
}
.dpad-button.pressed .Arrow_arrow-body {
  stroke: #17dfea;
}

.dpad-up {
  position: absolute;
  left: calc(var(--pixel-size) * 12);
  top: 0;
}
.dpad-down {
  position: absolute;
  bottom: var(--pixel-size);
  left: calc(var(--pixel-size) * 12);
}
.dpad-left {
  position: absolute;
  top: calc(var(--pixel-size) * 12);
  left: 0;
}
.dpad-right {
  position: absolute;
  top: calc(var(--pixel-size) * 12);
  right: 0;
}

.dpad {
  user-select: none;
}

.corner_topleft,
.corner_topright,
.corner_bottomleft,
.corner_bottomright {
  position: absolute;
  width: var(--pixel-size);
  height: var(--pixel-size);
  background: var(--bg);
  z-index: 2;
}

.corner_topleft {
  top: calc(var(--pixel-size) * -1);
  left: calc(var(--pixel-size) * -1);
}
.corner_topright {
  top: calc(var(--pixel-size) * -1);
  right: calc(var(--pixel-size) * -1);
}
.corner_bottomleft {
  bottom: calc(var(--pixel-size) * -1);
  left: calc(var(--pixel-size) * -1);
}
.corner_bottomright {
  bottom: calc(var(--pixel-size) * -1);
  right: calc(var(--pixel-size) * -1);
}

.headline {
  position: absolute;
  top: calc(var(--pixel-size) * 2);
  right: calc(var(--pixel-size) * 2);
  width: calc(var(--pixel-size) * 75);
}
